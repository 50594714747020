import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Camera from "react-html5-camera-photo";

function handleTakePhoto(dataUri: string) {
  // Do stuff with the photo...
  console.log("takePhoto");
}

function App() {
  return (
    <div className="App">
      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
      />
    </div>
  );
}

export default App;
